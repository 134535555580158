<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">Reset Password 🔒</h2>
        </b-link>

        <!-- <b-card-title class="mb-1">
            Welcome to Vuexy! 👋
          </b-card-title> -->
        <!-- <b-card-text class="mb-2">
            We will send you an email to reset your password
          </b-card-text> -->

        <!-- form -->
        <validation-observer ref="setForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- re type -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Confirm New Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="confirm Password"
                rules="required|min:8|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password-confirm"
                    v-model="confirm_password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isLoading"
              @click="validationForm"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="float-left"
                label="Floated Right"
              />
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>
        <p class="text-center mt-2">
          <b-link to="/login">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      confirm_password: "",
      password: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.setForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const payload = new FormData();
          payload.append("password", this.password);
          payload.append("password_confirmation", this.confirm_password);
          payload.append("token", this.$route.params.token);

          this.$httpAuth.post(`reset_password`, payload).then((response) => {
            if (response.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  text: response.data.message,
                },
              });
              this.$router.replace("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  text: response.data.message,
                },
              });
            }
            this.isLoading = false;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
